import React, { useCallback, useContext, useState } from 'react'

export const LanguageContext = React.createContext()
const LanguageUpdateContext = React.createContext()

export function useLanguage() {
  return useContext(LanguageContext)
}

export function useUpdateLanguage() {
  return useContext(LanguageUpdateContext)
}

/**
 * Sets up the language to use based on the browser language and enabled languages
 * @param {[string]} enabledLanguages - array of enabled languages
 * @returns {string} - the language to use
 */
export function setupLanguage(enabledLanguages) {
  const browserLanguage = navigator.languages
  for (const lang of browserLanguage) {
    for (const enabledLang of enabledLanguages) {
      if (enabledLang.includes(lang)) {
        return enabledLang
      }
    }
  }
  return enabledLanguages[0]
}

export function LanguageProvider({ children, enabledLanguages }) {
  const [language, setLanguage] = useState(setupLanguage(enabledLanguages))

  const changeLanguage = useCallback((newLanguage) => {
    setLanguage(newLanguage)
  }, [])

  return (
    <LanguageContext.Provider value={language}>
      <LanguageUpdateContext.Provider value={changeLanguage}>
        {children}
      </LanguageUpdateContext.Provider>
    </LanguageContext.Provider>
  )
}
