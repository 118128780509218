import React, { useEffect } from 'react'
import { useAuth } from 'oidc-react'

import api from '../../api'
import { BotsProvider } from '../../hooks/useBots'
/**
 * @type {React.LazyExoticComponent<React.ComponentType<import('../Layout/Layout')>>}
 */
const Layout = React.lazy(
  () => import(/* webpackChunkName: "Layout" */ '../Layout/Layout'),
)
/**
 * @type {React.LazyExoticComponent<React.ComponentType<import('../../pages/login/Login')>>}
 */
const Login = React.lazy(
  () => import(/* webpackChunkName: "Login" */ '../../pages/login/Login'),
)

function Auth() {
  const auth = useAuth()
  const userData = auth.userData

  useEffect(() => {
    if (userData) {
      api.setToken(userData.access_token)
    } else {
      api.setToken(null)
    }
  }, [userData])

  async function login() {
    await auth.signIn()
  }

  async function logout() {
    await auth.signOut()
  }

  if (auth.isLoading) {
    return <></>
  }

  const authenticated = userData && !userData.expired

  return authenticated ? (
    <BotsProvider>
      <Layout logout={logout.bind(this)} />
    </BotsProvider>
  ) : (
    <Login login={login.bind(this)} />
  )
}

export default Auth
