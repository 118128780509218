import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import pkg from '../package.json'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

export const SUPPORTED_LANGUAGES = ['de-DE', 'en-US', 'ja-JP', 'fr-FR', 'it-IT']

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en-US',
    supportedLngs: SUPPORTED_LANGUAGES,
    backend: {
      loadPath: '/locales/{{lng}}.json',
      queryStringParams: { v: pkg.version },
    },
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
