import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`

:root {
    --primary: #32B0A6 ; // Petrol Blue
    --primaryLight: #5FC2BB ; // Turquoise Blue
    --primaryLighter: #88E1DA ; // Lagoon Blue
    --primaryLightest: #C5F0ED; // Shy Blue

    --primaryBlack: #2A3139 ; // Jet Gray
    --primaryWhite: #FDFEFF; // Snow White
    --primaryGrey:  #C6CACE; // Precious Grey
    --primaryYellow: #EAB65A; // Pearling Yellow

    --success: #0BC08F;
    --warning: #EAB65A;
    --danger: #D15C57;
}

`
