import axios from 'axios'
import * as Sentry from '@sentry/react'

export const API_PREFIX = '/api/v1'

class Api {
  token = ''
  constructor() {
    this.server = API_PREFIX
  }

  /**
   * Performs a request to the API
   *
   * @param {import('axios').AxiosRequestConfig} data The axios request data
   * @returns {import('axios').AxiosPromise<any>} The response
   */
  async request(data) {
    data.timeout = data.timeout || 5000
    data.headers = data.headers
      ? { ...data.headers, Authorization: `Bearer ${this.token}` }
      : { Authorization: `Bearer ${this.token}` }
    let res
    try {
      res = await axios(data)
      return res.data
    } catch (err) {
      if (err.response && err.response.status === 400)
        Sentry.captureException(err)
      // We only rethrow the issue, if it's no connectivity
      if (err.code !== 'ECONNABORTED') {
        throw err
      }
    }
  }

  setToken(value) {
    this.token = value
  }

  keepAlive(timeout = 2000) {
    return this.request({
      method: 'get',
      url: '/keep-alive.html?ts=' + Date.now(),
      timeout: timeout,
    })
  }

  get(service, query = null, timeout = 5000) {
    if (query === null) {
      return this.request({
        method: 'get',
        url: `${this.server}${service}`,
        responseType: 'json',
        timeout: timeout,
      })
    } else {
      return this.request({
        method: 'get',
        url: `${this.server}${service}`,
        params: query,
        responseType: 'json',
        timeout: timeout,
      })
    }
  }

  post(service, data, timeout = undefined) {
    return this.request({
      method: 'post',
      url: `${this.server}${service}`,
      data: data,
      responseType: 'json',
      timeout: timeout,
    })
  }

  put(service, id, data) {
    return this.request({
      method: 'put',
      url: `${this.server}${service}/${id}`,
      data: data,
      responseType: 'json',
    })
  }

  delete(service, id) {
    return this.request({
      method: 'delete',
      url: `${this.server}${service}/${id}`,
      responseType: 'json',
    })
  }
}

export default new Api()
