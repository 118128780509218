import React from 'react'
import { AuthProvider } from 'oidc-react'
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import { compose } from 'recompose'

import themes, { overrides } from '../themes'
import Auth from './Auth/Auth'
import { LanguageProvider } from '../hooks/useLanguage'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import pkg from '../../package.json'
import api from '../api'

const theme = createMuiTheme({ ...themes.default, ...overrides })
const hostname = window.location.hostname

Sentry.init({
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: pkg.version + '+' + process.env.REACT_APP_BUILD_NUMBER,
  environment:
    hostname.indexOf('.neohelden.com') > -1
      ? hostname.replace('.neohelden.com', '')
      : hostname,
  dsn:
    hostname === 'localhost'
      ? null
      : 'https://ea8d9b6ef91642adacee7d3db09e3865@o529880.ingest.sentry.io/5648891',
})

class App extends React.Component {
  getPortalSettings = async () => {
    try {
      const portalConfig = await api.get('/configs?scope=portal')
      this.setState({
        authorityUrl: portalConfig.NIAM_OIDC_URL,
        clientId: portalConfig.NIAM_OIDC_CLIENT_ID_PORTAL,
        enabledLanguages: portalConfig.NEO_LANGUAGES_ENABLED,
        loaded: true,
      })
      clearInterval(this.state.intervalID)
      this.setState({ intervalID: null })
    } catch (err) {
      if (this.state.loaded) {
        this.setState({ loaded: false })
      }
    }
  }
  constructor(props) {
    super(props)
    this.state = {
      authorityUrl: null,
      clientId: null,
      loaded: false,
      enabledLanguages: [],
      intervalID: null,
    }
  }

  async componentDidMount() {
    this.getPortalSettings()
    const interval = setInterval(() => this.getPortalSettings(), 10_000)
    this.setState({
      intervalID: interval,
    })
  }

  render() {
    const { classes } = this.props
    const oidcConfig = {
      onSignIn: async () => {
        window.location.href = window.location.origin
      },
      authority: this.state.authorityUrl,
      clientId: this.state.clientId,
      responseType: 'code',
      redirectUri: window.location.origin,
      silentRedirectUri: window.location.origin,
      autoSignIn: false,
      automaticSilentRenew: true,
      loadUserInfo: false,
    }

    return (
      <MuiThemeProvider theme={theme}>
        {this.state.loaded ? (
          <AuthProvider {...oidcConfig}>
            <LanguageProvider enabledLanguages={this.state.enabledLanguages}>
              <Auth />
            </LanguageProvider>
          </AuthProvider>
        ) : (
          <div className={classes.loader}>
            <CircularProgress color="primary" size={80} />
          </div>
        )}
      </MuiThemeProvider>
    )
  }
}

const styles = () => ({
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
  },
})

export default compose(withStyles(styles, { withTheme: true }))(App)
