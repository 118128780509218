import tinycolor from 'tinycolor2'

const primary = '#32B0A6'
const primaryLighter = '#88E1DA'
const primaryLightest = '#C5F0ED'
const primaryLight = '#5FC2BB'
const primaryWhite = '#FDFEFF'

const secondary = '#32B0A6'
const warning = '#EAB65A'
const success = '#0BC08F'
const info = '#c6cace'
const danger = '#D15C57'
const lightenRate = 7.5
const darkenRate = 15

const defaultTheme = {
  typography: {
    fontFamily: 'Nunito, sans-serif',
  },
  palette: {
    primary: {
      main: primary,
      light: primaryLight,
      lighter: primaryLighter,
      lightest: primaryLightest,
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
      contrastText: primaryWhite,
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: primaryWhite,
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    danger: {
      main: danger,
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: '#000000',
      secondary: '#2a3139',
      hint: '#c6cace',
    },
    background: {
      default: '#f6f8fa',
      light: '#f6f8fa',
    },
  },
  customShadows: {
    widget:
      '0px 3px 11px 0px #dededeAA, 0 3px 3px -2px #dedede1A, 0 1px 8px 0 #dedede1A',
    widgetDark:
      '0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
    widgetWide:
      '0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: '#4A4A4A1A',
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          '0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A',
      },
    },
    MuiSelect: {
      icon: {
        color: '#B9B9B9',
      },
    },
    MuiListItem: {
      button: {
        '&$selected': {
          backgroundColor: '#F3F5FF !important',
          '&:focus': {
            backgroundColor: '#F3F5FF',
          },
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '24px',
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: primaryWhite,
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
        '&:nth-child(even)': {
          backgroundColor: '#f6f8fa',
        },
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: '1px solid rgba(224, 224, 224, .5)',
      },
      head: {
        fontSize: '0.95rem',
        backgroundColor: 'rgb(237, 239, 240) !important',
        fontWeight: 'bold',
      },
      body: {
        fontSize: '0.95rem',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '18px',
      },
      contained: {
        '&:hover': {
          backgroundColor: primaryLight,
        },
      },
      containedPrimary: {
        '&:hover': {
          backgroundColor: primaryLight,
        },
      },
      outlined: {
        borderRadius: '18px',
        '&:focus': {
          boxShadow: `none`,
        },
      },
      outlinedSecondary: {
        borderRadius: '18px',
        border: `2px solid ${secondary} `,
        '&:focus': {
          boxShadow: `none`,
        },
      },
    },
    MuiButtonBase: {
      root: {
        '&:focus': {
          boxShadow: `0 0 0 2px ${primary}, 0 0 0 0.2rem ${primaryLightest}`,
        },
      },
    },
  },
}

export default defaultTheme
