import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import GlobalStyle from './GlobalStyle'

// import i18n (needs to be bundled ;))
import './i18n'

ReactDOM.render(
  <Suspense fallback={<></>}>
    <GlobalStyle />
    <App />
  </Suspense>,
  document.getElementById('root'),
)
